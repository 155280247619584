import React from 'react'
import { pb } from '../../services/pocketbase';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../navigation/routes';
import LandingMainComponent from '../elements/landing/LandingMainComponent';
import { useAuth } from '../../store/AuthStore';



function LandingPage() {
    const { user } = useAuth();
    return !pb.authStore.isValid && !user ? <LandingMainComponent /> : <Navigate to={ROUTES.DASHBOARD} replace />;
}

export default LandingPage