/**
 * Highlights the given words in the provided text by wrapping them in HTML bold tags.
 *
 * @param {string} text - The original text to be updated.
 * @param {string[]} wordList - An array of words to be highlighted in the text.
 * @return {string} The updated text with the words highlighted.
 */
export const highlightWords = (text, wordList) => {
    let updatedText = text;
    wordList.forEach(word => {
        const regex = new RegExp(`\\b(${word}\\b)`, 'gi');
        updatedText = updatedText.replace(regex, '<b>$1</b>');
    });
    return updatedText;
};