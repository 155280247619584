import React from 'react'
import ReactTextareaAutosize from 'react-textarea-autosize'
import { useEnglishLessonStore } from '../store/englishLessonStore'
import AutoYoutubeGenerationComponent from './ai/AutoYoutubeGenerationComponent'

function TextAreaComponent() {
    const { inputText, setInputText, tokensCount } = useEnglishLessonStore()



    return (
        <>
            <AutoYoutubeGenerationComponent />
            <ReactTextareaAutosize value={inputText} onChange={(e) => setInputText(e.target.value)} maxRows={400} className='bg-solid textarea' style={{ borderRadius: '15px', padding: '10px', width: '100%' }} />
            <div onClick={() => console.log(process.env)} className={[' small', (Number(process.env.REACT_APP_TOKEN_LIMIT) || 0) / 2 > tokensCount ? 'green' : 'red'].join(" ")}>Количество токенов: {tokensCount}</div>
        </>
    )
}

export default TextAreaComponent