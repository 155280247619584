import React from 'react'
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router-dom'
import TextEditComponent from '../TextEditComponent'
import MainLayoutComponent from './MainLayoutComponent'
import ExerciseLayoutComponent from './ExerciseLayoutComponent'
import SignInPage from '../pages/SignInPage'
import ProtectedRouteComponent from './ProtectedRouteComponent'
import LandingPage from '../pages/LandingPage'
import { ROUTES } from './routes'
import FillTheGapExercisePage from '../pages/exercises/FillTheGapExercisePage'
import TestComponent from '../TestComponent'
import FindTheRightDefinitionPage from '../pages/exercises/FindTheRightDefinitionPage'

const router = createBrowserRouter(
    createRoutesFromElements(
        (
            <>
                <Route path={ROUTES.HOME} element={<LandingPage />} />
                <Route path={ROUTES.DASHBOARD} element={<MainLayoutComponent />}>
                    <Route path='' element={<ProtectedRouteComponent />}>
                        <Route index element={<TextEditComponent />} />
                        <Route path={ROUTES.EXERCISES} element={<ExerciseLayoutComponent />}>
                            <Route path={ROUTES.FILL_THE_GAPS} element={<FillTheGapExercisePage />} />
                            <Route path={ROUTES.FIND_RIGHT_DEFINITION} element={<FindTheRightDefinitionPage />} />
                        </Route>
                        <Route path={ROUTES.TEST_ROUTE} element={<TestComponent />} />
                    </Route>
                    <Route path={ROUTES.SIGNIN} element={<SignInPage />} />
                </Route>
            </>
        )
    )
)

function RouterComponent() {
    return (
        <RouterProvider router={router} />
    )
}

export default RouterComponent
