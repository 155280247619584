import React from 'react'
import { pb } from '../../services/pocketbase'
import useAuthStore, { useAuth } from '../../store/AuthStore'
import { NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { BoxArrowRight } from 'react-bootstrap-icons'
import { ROUTES } from '../navigation/routes'

function UserInfoNavComponent({ onClose }) {
    const { user } = useAuthStore()
    const { logout } = useAuth()

    return (
        <div className="user-info-nav pe-3">
            {
                pb.authStore.isValid && user ? (
                    <NavDropdown
                        title={user?.avatar && user?.avatar !== '' ? <div className='circle-image-sm'><img src={pb.files.getUrl(user, user.avatar)} alt={user.name} /></div> : <div className='circle-image-sm empty'></div>}
                        id={`offcanvasNavbarDropdown3-expand-sm`}
                        align={{ sm: 'end' }}
                        className='dropdown-user-info'
                    >
                        <NavDropdown.Item as={Link} to={ROUTES.SIGNIN} onClick={onClose}>Профиль</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={() => { onClose(); logout() }}> Выйти<BoxArrowRight style={{ marginLeft: '10px' }} /></NavDropdown.Item>
                    </NavDropdown>
                ) : (
                    <Link to={ROUTES.SIGNIN} onClick={onClose}>
                        Войти
                    </Link>
                )
            }
        </div>
    )
}

export default UserInfoNavComponent