import React from 'react'
import { useEnglishLessonStore } from '../../store/englishLessonStore'
import { highlightWords } from '../../functions/highlightWords'
import { copyToClipboard } from '../../functions/copyToClipboard'
import { makeCopyToClipboardText } from '../../functions/makeCopyToClipboardText'
import MinusButtonComponent from './buttons/MinusButtonComponent'
import CopyToClipboardButtonComponent from './buttons/CopyToClipboardButtonComponent'

function AutoMeaningResultComponent({ word, index }) {
    const { wordsDictionary, deleteAttributeFromDictionary, deleteWordFromDictionary } = useEnglishLessonStore()
    const item = wordsDictionary[word]
    const itemForCopy = { [word]: wordsDictionary[word] }

    const onClickCopyHandler = () => {

        copyToClipboard(makeCopyToClipboardText(itemForCopy, word))
    }

    return (
        <>
            {/* Render additional UI based on the definition state */}

            <div className='block-with-definition bg-blue shadow-box'>
                {item && <>
                    <div className='p-3'>
                        <div className='d-flex justify-content-between'><div>🔹<b>{word}</b><MinusButtonComponent title='Удалить все слово' onClick={() => { deleteWordFromDictionary(word) }} /></div><CopyToClipboardButtonComponent onClickHandler={onClickCopyHandler} /></div>
                        {item?.phonetics && (item?.phonetics.map((item, index) => (<div key={index}>{item} <MinusButtonComponent title='Удалить' onClick={() => { deleteAttributeFromDictionary(word, 'phonetics', item) }} /></div>)))}
                        <div className='py-2'>
                            {item?.definitions && (item?.definitions.map((item, index) => (<div key={index}>{item} <MinusButtonComponent title='Удалить' onClick={() => { deleteAttributeFromDictionary(word, 'definitions', item) }} /></div>)))}
                        </div>

                        {item?.usages && Array.isArray(item.usages) && item.usages.length > 0 && (
                            <ul>
                                {item.usages.map((usage, index) => (
                                    <li key={index} ><span dangerouslySetInnerHTML={{ __html: highlightWords(usage, [word]) }}></span> <MinusButtonComponent title='Удалить' onClick={() => { deleteAttributeFromDictionary(word, 'usages', usage) }} /></li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div>
                        {item?.translations && <div className='text-body-secondary'>
                            {item.translations.map((translation, index) => (
                                <div key={index} >{translation} <MinusButtonComponent title='Удалить' onClick={() => { deleteAttributeFromDictionary(word, 'translations', translation) }} /></div>
                            ))}
                        </div>
                        }
                    </div>
                </>}
            </div>
        </>
    )
}

export default AutoMeaningResultComponent