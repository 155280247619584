import React from 'react'
import { Col, Row } from 'react-bootstrap'
import AddToButtonComponent from '../../elements/buttons/AddToButtonComponent'
import { EXERCISE_TYPES } from './exerciseTypes'
import ExerciseBlockComponent from '../../elements/exercises/ExerciseBlockComponent'
import DeleteButtonComponent from '../../elements/buttons/DeleteButtonComponent'
import { useExersisesStore } from '../../../store/exercisesStore'

function FillTheGapExercisePage() {
    const { exercises, addTypeItemExercise, deleteTypeExercise } = useExersisesStore()
    const exerciseType = EXERCISE_TYPES.FILL_THE_GAP.id
    // Exercise structure

    const onAddExerciseClickHandler = () => {
        addTypeItemExercise(exerciseType, { words: [], sentences: [] })
    }

    const deleteButtonHandler = () => {
        const confirmed = window.confirm('Удалить все упражнения?');
        if (confirmed) {
            deleteTypeExercise(exerciseType)
        }
    }

    return (
        <>
            <Row>
                <Col className="col-sm-8">
                    <h1>{EXERCISE_TYPES.FILL_THE_GAP.name}</h1>
                    <div className='bg-solid br p-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <span></span>
                            {exercises && exercises[exerciseType] && exercises[exerciseType].length > 0 && <DeleteButtonComponent onClick={deleteButtonHandler} />}
                        </div>

                        <div>
                            {exercises && exercises[exerciseType] && exercises[exerciseType].map((exercise) => (
                                <ExerciseBlockComponent key={exercise.id} exercise={exercise} exerciseType={exerciseType} />
                            ))}
                            <span>Добавить задание <AddToButtonComponent onClick={onAddExerciseClickHandler} /></span>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default FillTheGapExercisePage