import React from 'react'
import { Col, Row } from 'react-bootstrap'
import AllWordsBlockComponent from './block/AllWordsBlockComponent'
import MainWorkAreaBlockComponent from './block/MainWorkAreaBlockComponent'

function TextEditComponent() {
    return (
        <>
            <Row className='reversed'>
                <Col sm={8}>
                    <MainWorkAreaBlockComponent />
                </Col>
                <Col sm={4} className='fixed'>
                    <AllWordsBlockComponent />
                </Col>
            </Row>
        </>
    )
}

export default TextEditComponent