import React, { useState } from 'react';
import { CheckLg, X } from 'react-bootstrap-icons';
import { useEnglishLessonStore } from '../store/englishLessonStore';

function WordsListItemComponent({ word }) {
    const { deleteFromWordList, editWordInList } = useEnglishLessonStore();
    const [editing, setEditing] = useState(false);
    const [newWord, setNewWord] = useState(word);

    const handleDelete = (word) => {
        deleteFromWordList(word);
    };

    const handleEdit = () => {
        if (newWord.trim() === '') {
            return;
        }
        editWordInList(word, newWord.trim());
        setEditing(false);
    };

    return (
        <div className='words-list-item-component rounded shadow-box'>
            {editing ? (
                <>
                    <span variant='outline-danger' className='edit-btn' onClick={() => setEditing(false)}><X /></span>
                    <span className='save-btn' onClick={handleEdit}><CheckLg /></span>
                    <input
                        type="text"
                        value={newWord}
                        style={{ width: `${newWord.length}ch` }}
                        onChange={(e) => { setNewWord((e.target.value)) }}
                        className='edit-input'
                    />


                </>
            ) : (
                <>
                    <span onClick={() => setEditing(true)}>{word}</span>
                    <span className='delete-btn' onClick={() => handleDelete(word)} style={{ marginLeft: '10px' }}><X /></span>

                </>
            )}
        </div>
    );
}

export default WordsListItemComponent;