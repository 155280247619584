import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function FooterComponent() {
    return (
        <Container fluid className="footer text-center mt-5 shadow-box">
            <Row>
                <Col md={8}>
                   
                </Col>
                <Col md={4}>
                    
                </Col>            
            </Row>
               <Row>
                <Col md={12} className='p-2'>
                   <div className="footer-text fs-6 fw-light">LessonCrafter AI</div>
                   <div className="footer-text small py-2fw-light">Vitaliyhan © 2024</div>
                </Col>         
            </Row>
        </Container>
    )
}

export default FooterComponent