import React from 'react'
import LoadingComponent from '../LoadingComponent';
import UpdateButtonComponent from '../buttons/UpdateButtonComponent';
import AddToButtonComponent from '../buttons/AddToButtonComponent';
import { useExersisesStore } from '../../../store/exercisesStore';

function GeneratedFillTheGapSentensesComponent({ result, status, word, updateByWord, definitions, exerciseType, exercise }) {
    const { editTypeItemExercise } = useExersisesStore()
    // console.log({ exercises })
    const addSentenceToExercise = (sentence) => {
        const existingSentences = [...exercise.sentences];
        !existingSentences.includes(sentence) && editTypeItemExercise(exerciseType, exercise.id, {
            ...exercise,
            words: [...exercise.words],
            sentences: [...existingSentences, sentence]
        });
    }


    const objResult = (result && typeof result === 'string') ? JSON.parse(result) : null;
    return (
        <>
            <div>
                <h4>{word} <UpdateButtonComponent onClick={() => updateByWord(word)} /> {!!status && <><LoadingComponent /></>}</h4>
            </div>
            <div className='block-with-definition shadow-box'>
                {result && objResult && objResult.sentences && objResult.sentences.map((sentence) => (
                    <div>{sentence} {!exercise.sentences.includes(sentence) && <AddToButtonComponent onClick={() => { addSentenceToExercise(sentence) }} />}</div>
                ))}
            </div>
        </>
    )
}

export default GeneratedFillTheGapSentensesComponent