import { pb } from "./pocketbase";

/**
 * Makes a GET request to the Dictionary API to retrieve the definition of a given word.
 *
 * @param {string} word - The word to look up in the dictionary.
 * @param {function} setResult - A callback function to handle the API response data.
 * @param {function} setStatus - A callback function to handle any error messages.
 * @return {void}
 */
export const makeRequestDictionaryApi = async (word, setResult, setStatus) => {
    var headers = {};
    headers["Authorization"] = `Bearer ${pb.authStore.token}`;
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/dictionaryapi/${word}`, {
            method: 'GET',
            headers: headers
        });
        if (!response.ok) {
            throw new Error('Word not found');
        }
        const data = await response.json();
        setResult(data);
        // console.log({ data });
    } catch (err) {
        if (err.name === 'Error' && err.message === 'Word not found') {
            setStatus('The word could not be found in the dictionary.');
            setResult({})
        } else {
            // Handle other types of errors by setting a generic status message.
            setStatus(err.message || 'An error occurred while fetching the definition.');
            setResult({})
        }
    }
};