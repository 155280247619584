import React from 'react'
import { Button } from 'react-bootstrap'

function GenerateButtonComponent({ onClick, children, style, className , disabled}) {
    return (
        <Button onClick={onClick} style={style} className={className} disabled={disabled}>
            {children}
        </Button>
    )
}

export default GenerateButtonComponent