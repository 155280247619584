import React, { useState } from 'react';
import { ROUTES } from '../../navigation/routes';
import { Link } from 'react-router-dom';
import { Button, Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import LogoComponent from '../LogoComponent';
import styles from '../../../../Landing.module.css'; // Import CSS module
import { useEnglishLessonStore } from '../../../store/englishLessonStore';

const LandingMainComponent = () => {
    const {setTheme, theme }= useEnglishLessonStore()
    const [expanded, setExpanded] = useState(false);
    const onClose = () => {
        setExpanded(false);
    };
    const onOpen = () => {
        setExpanded(true);
    };
    const expand = 'sm';
    // change theme to light if it is not
    if (theme==="dark") {
        setTheme("light")
    }


    return (
        <>
            <div className={`${styles['landing-container']}`} >


                <div className={`${styles['landing']}  ${styles['nunito']}`}>
                    <Navbar key={expand} expand={expand} expanded={expanded} className={`mb-3 ${styles['shadow-box']}`} sticky="top">
                        <Container className={`d-flex justify-content-between  ${styles['nunito']}`}>
                            <Navbar.Brand as={Link}><LogoComponent /> LessonCrafter AI</Navbar.Brand>
                            <Navbar.Toggle onClick={onOpen} aria-controls={`offcanvasNavbar-expand-${expand}`} />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                onHide={onClose}
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className={`${styles['nunito']}`}>
                                        <LogoComponent /> LessonCrafter AI
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body className='align-items-center'>
                                    <Nav className="justify-content-between flex-grow-1 pe-3">
                                        <div className={`d-${expand}-flex`}>
                                            <Nav.Link onClick={onClose} href='#' className={styles.black}>Функции</Nav.Link>
                                            <Nav.Link onClick={onClose} className={styles.black}>Цена</Nav.Link>
                                        </div>
                                        <Button as={Link} to={ROUTES.DASHBOARD} className={`${styles['nice-button']}`}>Войти</Button>
                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                </div>

                <main className={`${styles['main']}`}>
                    <div className={`position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light ${styles['pattern-bg']}`}>
                        <div className={`${styles.blob} position-absolute top-0 left-0 w-100 h-100`}></div>
                        <div className="col-md-8 p-lg-5 mx-auto my-5 position-relative z-3">
                            <h1 className={`display-1 ${styles['logo-color']} ${styles['hero-title']} ${styles.backdrop}  ${styles['nunito']}`}>LessonCrafter AI</h1>
                            <p className={`display-5 ${styles['hero-text']} ${styles.backdrop}  ${styles['nunito']}`}>автоматизированное создание уроков по&nbsp;английскому языку</p>
                            <Button as={Link} to={ROUTES.DASHBOARD} className={`${styles['nice-button']} ${styles['text-button-big']} ${styles.backdrop}  ${styles['nunito']}`}>Попробовать</Button>
                        </div>
                        <div className={` ${styles['product-device']} shadow-sm d-none d-md-block z-1 `}></div>
                        <div className={`${styles['product-device']}  ${styles['product-device-2']} shadow-sm d-none d-md-block z-2`}></div>
                    </div>
                    <Container>
                        <div className="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3">
                            <div className=" me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
                                <div className="my-3 py-3">
                                    <h2 className={`display-4  ${styles['nunito']}`}>Подбор лексики для урока</h2>
                                    <p className={`lead  ${styles['nunito']}`}>ИИ подбирает лексику для урока по уровню сложности, ищет значения слов и&nbsp;фраз в&nbsp;словарях.</p>
                                </div>
                            </div>
                            <div className="me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
                                <div className="my-3 py-3">
                                    <h2 className={`display-4  ${styles['nunito']}`}>Создание упражнений</h2>
                                    <p className={`lead  ${styles['nunito']}`}>ИИ создает упражнения на&nbsp;выбранные темы с использованием слов и&nbsp;фраз из&nbsp;текста.</p>
                                </div>
                            </div>
                        </div>
                    </Container>
                </main>

                <Container fluid className={`py-2 ${styles['shadow-box']}`}>
                    <div className="row">
                        <div className={`col-12 col-md d-flex justify-content-${expand}-center`}>
                            <div className='align-self-center text-center'>
                                <span><LogoComponent /> LessonCrafter AI</span>
                                <small className="d-block mb-3 text-muted small">&copy; 2017–2024</small>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default LandingMainComponent;
