import React from 'react'

import '../../../App.css';
import { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import NavBarNavigationComponent from './NavBarNavigationComponent';
import FooterComponent from './FooterComponent';

function MainLayoutComponent() {
    return (
        <>
            <Toaster toastOptions={{ className: 'toast-style' }} />
            <div className="App wrapper" >
                <NavBarNavigationComponent />
                <div className="content">
                    <Container fluid>
                        <Outlet />
                        <div className="blob" ></div>
                    </Container>
                </div>

                <FooterComponent />
            </div>
        </>
    )
}

export default MainLayoutComponent