export const makeCopyToClipboardText = (wordsDictionary, key) => {
    if (!wordsDictionary.hasOwnProperty(key)) return ''; // Проверяем наличие ключа и возвращаем пустую строку если ключ не существует
//🔹
    let wordString = `🔹${key}\n`;
    
    const appendArray = (array) => {
        array && array.forEach((value) => { 
            if (typeof value === 'string') { // Проверяем, что значение является строкой
                // wordString += highlightWords(value, [`${key}`]) + '\n';
                wordString += value + '\n';
            } else {
                console.warn(`Invalid value found in the array for key ${key}:`, value);
            }
        });
    };

    // Добавляем данные в строку
    appendArray(wordsDictionary[key].phonetics);
    wordString += '\n';
    appendArray(wordsDictionary[key].definitions);
    wordString += '\n';
    appendArray(wordsDictionary[key].usages);
    wordString += '\n\n';
    appendArray(wordsDictionary[key].translations);
    wordString += '\n\n';

    return wordString;
};