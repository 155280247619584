import React, { useState } from 'react';

function TranscriptFetcher() {
    const [url, setUrl] = useState('');
    const [transcript, setTranscript] = useState('');
    const [error, setError] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch('https://yt-dev.alexandra-lovenglish.ru/transcribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ url }),
            });

            if (!response.ok) {
                throw new Error(await response.json());
            }

            const data = await response.json();
            setTranscript(data.text);
            setError(null);
            
        } catch (err) {
            setError(err.message);
            setTranscript('');
        }
    };

    return (
        <div>
            <h1>YouTube Transcript Fetcher</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="Enter YouTube URL"
                    required
                />
                <button type="submit">Fetch Transcript</button>
            </form>
            {transcript && (
                <div>
                    <h2>Transcript:</h2>
                    <p>{transcript}</p>
                </div>
            )}
            {error && (
                <div style={{ color: 'red' }}>
                    <h2>Error:</h2>
                    <p>{error}</p>
                </div>
            )}
        </div>
    );
}



function TestComponent() {

    return (
        <div>
            <TranscriptFetcher />
        </div>
    )
}

export default TestComponent