import React, { useState } from 'react'
import { Container, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import ThemeButtonComponent from '../elements/ThemeButtonComponent';
import { useEnglishLessonStore } from '../../store/englishLessonStore';
import LogoComponent from '../elements/LogoComponent';
import UserInfoNavComponent from '../elements/UserInfoNavComponent';
import { pb } from '../../services/pocketbase';
import { ROUTES } from './routes';
import { useAuth } from '../../store/AuthStore';

function NavBarNavigationComponent() {
    const { theme } = useEnglishLessonStore()
    const { user } = useAuth()
    const [expanded, setExpanded] = useState(false);
    const expand = 'sm'
    const onClose = () => {
        setExpanded(false)
    }
    const onOpen = () => {
        setExpanded(true)
    }

    const location = useLocation();

    return (
        <>
            <Navbar key={expand} expand={expand} expanded={expanded} className="navbar-themed mb-3 shadow-box bg-solid" sticky="top">
                <Container fluid>
                    <Navbar.Brand as={Link} to={pb.authStore.isValid && user ? ROUTES.DASHBOARD : ROUTES.HOME}><LogoComponent /></Navbar.Brand>
                    <Navbar.Toggle onClick={onOpen} aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Navbar.Offcanvas
                        data-bs-theme={theme}
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        onHide={onClose}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                <LogoComponent /> LessonCrafter AI
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className='align-items-center'>
                            <Nav className="justify-content-start flex-grow-1 pe-3">
                                <div className={`d-block d-${expand}-none`}>
                                    <UserInfoNavComponent onClose={onClose} />
                                </div>
                                <Nav.Link onClick={onClose} active={location.pathname === ROUTES.DASHBOARD} as={Link} to={ROUTES.DASHBOARD}>Работа с текстом</Nav.Link>
                                <NavDropdown
                                    title="Упражнения"
                                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                                >
                                    {[{ label: 'Заполни пропуски', route: ROUTES.FILL_THE_GAPS }, { label: 'Найди правильное определение', route: ROUTES.FIND_RIGHT_DEFINITION }].map((item) =>
                                        <NavDropdown.Item key={item.label} onClick={onClose} as={Link} to={item.route} active={location.pathname === item.route} >{item.label}</NavDropdown.Item>
                                    )}
                                </NavDropdown>
                            </Nav>
                            <ThemeButtonComponent />
                            <div className={`d-none d-${expand}-block`}>
                                <UserInfoNavComponent onClose={onClose} />
                            </div>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </>
    )
}

export default NavBarNavigationComponent