
import { create } from 'zustand';
import { pb } from '../services/pocketbase';
import { createJSONStorage, persist } from 'zustand/middleware';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../components/navigation/routes';


const useAuthStore = create(
    persist(
        (set, get) => ({
            user: null,
            isLoading: true,
            authData: null,
            login: async (provider) => {
                try {
                    var w = window.open()
                    pb.authStore.clear()
                    const authData = await pb.collection('users').authWithOAuth2({
                        provider,
                        urlCallback: (url) => {
                            w.location.href = url
                        },
                    })
                    const meta = authData.meta;

                    if (meta.isNew) {
                        const formData = new FormData();

                        const response = await fetch(meta.avatarUrl);

                        if (response.ok) {
                            const file = await response.blob();
                            formData.append('avatar', file);
                        }

                        formData.append('name', meta.name);

                        await pb.collection('users').update(authData.record.id, formData);
                    }


                    set({ authData: authData })
                    set({ user: authData.record, isLoading: false });

                } catch (error) {
                    set({ user: null, isLoading: false });
                }
            },
            logout: () => {
                pb.authStore.clear()
                set({ user: null, isLoading: false,authData: null });
            },
        }),
        {
            name: 'auth-storage',
            storage: createJSONStorage(() => localStorage),
        }
    )
);

// Create a custom hook to handle logout with navigation
export const useAuth = () => {
    const navigate = useNavigate();
    const authStore = useAuthStore();

    const logoutWithRedirect = () => {
        authStore.logout();
        navigate(ROUTES.SIGNIN, { replace: true });
    };

    return { ...authStore, logout: logoutWithRedirect };
};

export default useAuthStore;