import React from 'react'
import { highlightWords } from '../../functions/highlightWords';
import { BoxArrowInUp } from 'react-bootstrap-icons';
import { useEnglishLessonStore } from '../../store/englishLessonStore';
import AddToButtonComponent from './buttons/AddToButtonComponent';
import LoadingComponent from './LoadingComponent';
import UpdateButtonComponent from './buttons/UpdateButtonComponent';

function AutoMeaningItemComponent({ word, result, status, updateByIndex, index }) {
    const { wordsDictionary, addWordToDictionary } = useEnglishLessonStore()
    // dangerouslySetInnerHTML={{ __html: result.trim() }}
    // dangerouslySetInnerHTML={{ __html: result.trim() }}
    const objResult = (result && typeof result === 'string') ? JSON.parse(result) : null;

    const definitionsAlreadyExists = wordsDictionary[word]?.definitions.includes(objResult?.definition);
    const translationsAlreadyExists = wordsDictionary[word]?.translations.includes(objResult?.russianTranslation);

    const onAddHandle = () => {
        addWordToDictionary(word, [objResult?.definition], objResult?.usages, [objResult?.russianTranslation])

    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }} className='fw-bold'>
                <h4> 🤖 {!!status &&
                    <LoadingComponent />
                }</h4>
                <UpdateButtonComponent onClick={() => updateByIndex(index)} />
            </div>
            {/* </div> */}

            {/* Render additional UI based on the definition state */}

            <div className='bg-light-light block-with-definition'>
                {result && objResult && <>
                    <div className='p-3'>
                        {objResult?.word && <div>🔹<b>{objResult.word}</b><BoxArrowInUp title='Добавить всё' className='add-all-to-dictionary-btn' onClick={onAddHandle} /></div>}
                        {objResult?.definition && <div className='py-2'>{objResult.definition} {definitionsAlreadyExists ? '' : <AddToButtonComponent onClick={() => { addWordToDictionary(word, [objResult.definition]) }} />} </div>}
                        {objResult?.usages && Array.isArray(objResult.usages) && objResult.usages.length > 0 && (
                            <ul>
                                {objResult.usages.map((usage, index) => (
                                    <li key={index} ><span dangerouslySetInnerHTML={{ __html: highlightWords(usage, [word]) }}></span>{wordsDictionary[word]?.usages.includes(usage) ? '' : <AddToButtonComponent onClick={() => { addWordToDictionary(word, [], [usage]) }} />}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div>{objResult?.russianTranslation && <div className='text-body-secondary'>{objResult.russianTranslation}{translationsAlreadyExists ? '' : <AddToButtonComponent onClick={() => { addWordToDictionary(word, [], [], [objResult.russianTranslation]) }} />}</div>}</div>
                </>}
            </div>

        </div>
    )
}

export default AutoMeaningItemComponent