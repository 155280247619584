import React from 'react'
import ClearWords from '../elements/ClearWords'
import WordsAddFormComponent from '../WordsAddFormComponent'
import WordsListComponent from '../WordsListComponent'
import { Col, Row } from 'react-bootstrap'
import WordsListHeaderComponent from '../elements/WordsListHeaderComponent'
import AutoChooseComponent from '../ai/AutoChooseComponent'

function AllWordsBlockComponent() {
    return (
        <>
            <Row>
                <Col className='justify-content-between align-items-center d-flex'>
                    <WordsListHeaderComponent />
                    <ClearWords />
                </Col>
            </Row>
            <Row>
                <Col>
                    <WordsAddFormComponent />
                </Col>
            </Row>
            <Row>
                <Col>
                    <WordsListComponent />
                </Col>
            </Row> 
            <Row>
                <Col>
                    <AutoChooseComponent />
                </Col>
            </Row>
        </>
    )
}

export default AllWordsBlockComponent