import React from 'react'
import { useEnglishLessonStore } from '../../../store/englishLessonStore'
import MinusButtonComponent from '../buttons/MinusButtonComponent'
import ExerciseTitleComponent from './ExerciseTitleComponent'
import { useExersisesStore } from '../../../store/exercisesStore'

function FindRightDefinitionChooseWordsComponent({ exercise, exerciseType }) {
    const { wordsDictionary } = useEnglishLessonStore()
    const { editTypeItemExercise } = useExersisesStore()

    const addWordsToExercise = (wordObj) => {
        if (!exercise.pairs.find(item => item.word === wordObj.word)) {
            editTypeItemExercise(exerciseType, exercise.id, { ...exercise, pairs: [...exercise.pairs, { word: wordObj.word, definition: wordObj?.info?.definitions[0] ? wordObj.info.definitions[0] : '' }] })
        }
    }

    const deleteWordsFromExercise = (word) => {
        const updatedPairs = exercise.pairs.filter(item => item.word !== word);
        editTypeItemExercise(exerciseType, exercise.id, { ...exercise, pairs: updatedPairs });
    }

    return (
        <>
            <div>
                <ExerciseTitleComponent>Выберите слова</ExerciseTitleComponent>
                <div className='d-flex flex-wrap'>
                    {
                        Object.keys(wordsDictionary).filter(key => ![...(exercise.pairs ? exercise.pairs.map(w => w.word) : []), ''].flat().includes(key)).map((key) => (
                            <span className="words-list-item-component pointer" key={"wordsDictionary" + key} onClick={() => addWordsToExercise({ info: wordsDictionary[key], word: key })}>{key}</span>
                        ))
                    }
                </div>
            </div>
            {exercise?.pairs && <ExerciseTitleComponent>Выбранные</ExerciseTitleComponent>}
            <div className='d-flex flex-wrap'>
                {exercise?.pairs && exercise.pairs.map((item) => (
                    <span className="words-list-item-component " key={"exerciseWords" + item.word}>{item.word} <MinusButtonComponent title="удалить" onClick={() => { deleteWordsFromExercise(item.word) }} /></span>
                ))}
            </div >
        </>
    )
}

export default FindRightDefinitionChooseWordsComponent