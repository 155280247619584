import toast from "react-hot-toast";
import { ai } from "../ai";
import LangflowClient from "./langflowClient";

/**
 * Replaces placeholders in a given text with variables.
 *
 * @param {string} text - The text containing placeholders.
 * @param {object} vars - An object containing variables to replace placeholders.
 * @return {string} The text with placeholders replaced.
 */
const replacePlaceholders = (text, vars) => {
    return text.replace(/\{(.*?)\}/g, (match, p1) => {
        return vars[p1] || ""; // If the variable is not found, keep the placeholder
    });
};


/**m,
 * Makes a request to the LangflowClient with the given parameters.
 *
 * @param {string} aiName - The name of the AI to use for the request.
 * @param {function} setStatusInner - A function to set the status of the request.
 * @param {object} varsInner - An object containing variables to replace placeholders.
 * @param {function} setResultInner - A function to set the result of the request.
 * @return {Promise<void>} A Promise that resolves when the request is completed.
 */
export const makeRequestAi = async (aiName, setStatusInner, varsInner, setResultInner) => {
    const langflowClient = new LangflowClient();
    console.log('Running...');
    setStatusInner(true);
    try {
        const flowIdOrName = ai[aiName].flowIdOrName;
        const inputValue = replacePlaceholders(ai[aiName].inputValue, varsInner);
        console.log("inputValue", inputValue)
        const response = await langflowClient.runFlow(
            flowIdOrName,
            inputValue,
            {},
            false,
            (data) => console.log("Received:", data.chunk), // onUpdate
            (message) => console.log("Stream Closed:", message), // onClose
            (error) => console.log("Stream Error:", error) // onError
        );
        const flowOutputs = response.outputs[0];
        const firstComponentOutputs = flowOutputs.outputs[0];
        const output = firstComponentOutputs.outputs.message;
        setStatusInner(false);
        setResultInner(output.message.text)
        console.log("Final Output:", output.message.text);

    } catch (error) {
        console.error('Error running flow:', error);
        toast.error("Нет поключения к langflow")
        setStatusInner(0);
    }
};