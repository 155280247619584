import React from 'react'
import DeleteButtonComponent from '../buttons/DeleteButtonComponent'
import { Col, Container, Row } from 'react-bootstrap'
import ChooseWordsComponent from './ChooseWordsComponent'
import GenerateSentencesFillTheGapComponent from './GenerateSentencesFillTheGapComponent'
import { useExersisesStore } from '../../../store/exercisesStore'
import MinusButtonComponent from '../buttons/MinusButtonComponent'

function ExerciseBlockComponent({ exercise, exerciseType }) {
  const { deleteTypeItemExercise, editTypeItemExercise } = useExersisesStore()

  const deleteHandler = () => {
    if (window.confirm('Удалить задание?')) {
      deleteTypeItemExercise(exerciseType, exercise.id)
    }
  }

  const wordsString = exercise?.words && exercise.words.map((item) => (
    item.word
  )).join(', ')
  console.log({ exercise })
  const sentencesArray = exercise?.sentences && exercise.sentences

  const deleteSentenceFromExercise = (sentence) => {
    editTypeItemExercise(exerciseType, exercise.id, { 
        ...exercise, 
        words: [...exercise.words], 
        sentences: exercise.sentences.filter(item => item !== sentence) 
    });
}

  return (
    <>
      <div className='block-with-definition bg-blue shadow-box'>
        <div className='d-flex justify-content-between'>
          <div>{wordsString}</div>
          <span className='px-2'><DeleteButtonComponent onClick={deleteHandler} /></span>
        </div>
        <div>
          {sentencesArray && sentencesArray.map((sentence, index) => (
            <div key={index}>{sentence} <MinusButtonComponent onClick={() => deleteSentenceFromExercise(sentence)}/></div>
          ))
          }
        </div>

      </div>
      <div className='block-with-definition shadow-box'>
        <Row>
          <Col>
            <ChooseWordsComponent exercise={exercise} exerciseType={exerciseType} />
          </Col>
        </Row>
        <Row>
          <Col>
            <GenerateSentencesFillTheGapComponent exerciseType={exerciseType} exercise={exercise} />
          </Col>
        </Row>

      </div>

    </>

  )
}

export default ExerciseBlockComponent