import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'

function ExerciseLayoutComponent() {
    return (
        <>
            <Row>
                <Col>
                    <Outlet />
                </Col>
            </Row>
        </>
    )
}

export default ExerciseLayoutComponent