import React, { useState } from 'react';
import { useEnglishLessonStore } from '../../store/englishLessonStore';
import AutoMeaningItemComponent from '../elements/AutoMeaningItemComponent';
import { Form, ProgressBar } from 'react-bootstrap';
import AutoMeaningResultComponent from '../elements/AutoMeaningResultComponent';
import AutoMeaningApiDictionariesComponent from '../elements/AutoMeaningApiDictionariesComponent';
import { makeRequestAi } from '../../services/makeRequestAi';
import { makeRequestDictionaryApi } from '../../services/makeRequestDictionaryApi';
import toast from 'react-hot-toast';
import { makeCopyToClipboardText } from '../../functions/makeCopyToClipboardText';

import GenerateButtonComponent from '../elements/buttons/GenerateButtonComponent';
import DeleteButtonComponent from '../elements/buttons/DeleteButtonComponent';
import CopyToClipboardButtonComponent from '../elements/buttons/CopyToClipboardButtonComponent';
import { copyToClipboard } from '../../functions/copyToClipboard';

function AutoMeaningComponent() {
    const { wordList, inputText, wordsDictionary, clearWordsDictionary, addWordToDictionary } = useEnglishLessonStore();
    const [isChecked, setIsChecked] = useState(JSON.stringify(wordsDictionary) !== '{}' ? false : true);

    // Initialize StatusState and ResultState as objects instead of arrays
    const [StatusState, setStatusState] = useState(wordList.reduce((acc, word) => {
        acc[word] = false;
        return acc;
    }, {}));
    const [ResultState, setResultState] = useState(wordList.reduce((acc, word) => {
        acc[word] = "";
        return acc;
    }, {}));

    const [ResultDictApiState, setResultDictApiState] = useState(wordList.reduce((acc, word) => {
        acc[word] = {}
        return acc;
    }, {}));

    const [ErrorDictApiState, setErrorDictApiState] = useState(wordList.reduce((acc, word) => {
        acc[word] = "";
        return acc;
    }, {}));

    const updateStatusState = (word, newValue) => {
        setStatusState((prevState) => ({ ...prevState, [word]: newValue }));
    };
    const updateResultState = (word, newValue) => {
        setResultState((prevState) => ({ ...prevState, [word]: newValue }));
    };


    const addAllToDictionaryAndResultState = (word, newState) => {
        updateResultState(word, newState)
        if (isChecked) {
            const objResult = (newState && typeof newState === 'string') ? JSON.parse(newState) : null;
            addWordToDictionary(word, [objResult?.definition], objResult?.usages, [objResult?.russianTranslation])
        }
    };
    // ai
    const handleStartButtonClick = async () => {
        setIsChecked(false);
        // console.log('start handleStartButtonClick');
        const requests = wordList.map(async (word) => {
            await makeRequestAi(
                'AutoMeaning',
                (newState) => updateStatusState(word, newState),
                { text: inputText, word: word },
                (newState) => { addAllToDictionaryAndResultState(word, newState); }
            );
        })
        await Promise.all(requests);
    };

    const updateByIndex = async (index) => {
        // console.log('start updateByIndex');
        await makeRequestAi(
            'AutoMeaning',
            (newState) => updateStatusState(wordList[index], newState),
            { text: inputText, word: wordList[index] },
            (newState) => updateResultState(wordList[index], newState)
        );
    };

    // dictionary
    const updateResultDictApiState = (word, newValue) => {
        setResultDictApiState((prevState) => ({ ...prevState, [word]: newValue }));
    };

    const updateErrorDictApiState = (word, newValue) => {
        setErrorDictApiState((prevState) => ({ ...prevState, [word]: newValue }));
    };
    // dictionary

    const handleCallDictionaryButtonClick = async () => {
        wordList.forEach(async (word) => {
            makeRequestDictionaryApi(word,
                (newState) => updateResultDictApiState(word, newState),
                (errMessage) => updateErrorDictApiState(word, errMessage)
            );

        });
    };

    //
    // const handleCallDictionaryButtonClick = async () => {
    //     wordList.forEach(async (word) => {
    //         makeRequestDictionaryApi(word, 
    //             (newState) => setResultDictApiState(prevState => ({ ...prevState, [word]: newState })), 
    //             (errMessage) => setErrorDictApiState(prevState => ({ ...prevState, [word]: errMessage }))
    //         );
    //     });
    // };

    // count progress bar
    const countProgressBar = () => {
        let count = 0;
        for (let value of Object.values(StatusState)) {
            if (value) {
                count++;
            }
        }
        let result = (wordList.length - count) / wordList.length * 100;
        return result;
    };

    const onCopyClickHandler = () => {
        if (JSON.stringify(wordsDictionary) !== '{}') {
            // Convert the array to a comma-separated string
            var wordString = '';
            for (let key in wordsDictionary) {
                if (wordsDictionary.hasOwnProperty(key)) { // Исключаем унаследованные свойства
                    wordString += makeCopyToClipboardText(wordsDictionary, key);
                }
            }
            // Copy the string to the clipboard
            copyToClipboard(wordString)
        } else {
            alert('The word list is empty.');
        }
    }

    const clearWordsDictionaryHandler = () => {
        clearWordsDictionary()
        toast.success('Слова удалены');
    }


    return (
        <div>
            <div className='d-flex align-items-center justify-content-between'>
                <div>
                    <GenerateButtonComponent onClick={handleStartButtonClick} className="start-button mb-3" disabled={wordList.length === 0}>Подобрать значения c AI</GenerateButtonComponent>
                    <div className='mx-2' style={{ display: 'inline-flex' }}>
                        <Form.Check.Input type='checkbox' checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                        <Form.Check.Label>В словарь</Form.Check.Label>
                    </div>
                    <GenerateButtonComponent onClick={handleCallDictionaryButtonClick} className="start-button mb-3 mx-3" disabled={wordList.length === 0} >Загрузить из словаря</GenerateButtonComponent>
                </div>
                {JSON.stringify(wordsDictionary) !== '{}' ? <div><CopyToClipboardButtonComponent onClickHandler={onCopyClickHandler} /><DeleteButtonComponent onClick={() => window.confirm('Удалить все слова?') && clearWordsDictionaryHandler()} title="Удалить все слова" /></div> : ''}
            </div>

            {wordList.length > 0 ? countProgressBar() < 100 ? <ProgressBar animated now={countProgressBar()} label={`${Math.round(countProgressBar())}%`} /> : null : null}
            <div className='pt-3'>
                {wordList.map((word, index) => (
                    <React.Fragment key={index}>
                        <div>
                            <div className='pt-5'>
                                <div style={{ display: 'flex', alignItems: 'center' }} className='fw-bold'><h4>{word}</h4></div>
                            </div>
                            <AutoMeaningResultComponent word={word} />
                            <AutoMeaningItemComponent word={word} result={ResultState[word]} status={StatusState[word]} index={index} updateByIndex={updateByIndex} />
                            <AutoMeaningApiDictionariesComponent word={word} dictionaryInfo={ResultDictApiState[word]} error={ErrorDictApiState[word]} />
                        </div>
                    </React.Fragment>

                ))
                }
            </div >
        </div >
    );
}

export default AutoMeaningComponent;