import React, { useState } from 'react'
import ExerciseTitleComponent from './ExerciseTitleComponent'
import GenerateButtonComponent from '../buttons/GenerateButtonComponent'
import { makeRequestAi } from '../../../services/makeRequestAi'
import GeneratedFillTheGapSentensesComponent from './GeneratedFillTheGapSentensesComponent'


function findDefinitions(wordToFind, data) {
    for (const item of data.words) {
        if (item.word[0] === wordToFind) {
            return item.definitions;
        }
    }
    return null;
}

function GenerateSentencesFillTheGapComponent({ exercise, exerciseType }) {

    const [StatusState, setStatusState] = useState(exercise.words.reduce((acc, word) => {
        acc[word] = false;
        return acc;
    }, {}));

    const [ResultState, setResultState] = useState(exercise.words.reduce((acc, word) => {
        acc[word] = "";
        return acc;
    }, {}));

    const updateStatusState = (word, newValue) => {
        setStatusState((prevState) => ({ ...prevState, [word]: newValue }));
    };
    const updateResultState = (word, newValue) => {
        setResultState((prevState) => ({ ...prevState, [word]: newValue }));
    };




    /*  
    {exercise?.words && exercise.words.map((item) => (
                        <span className="words-list-item-component" key={"exerciseWords" + item.word}>{item.word} <MinusButtonComponent title="удалить" onClick={() => { deleteWordsFromExercise(item.word) }} /></span>
    ))}

       const vars = {
                word: '',
                meaning: '',
            }
    */
    const handleStartButtonClick = async () => {
        // console.log('start handleStartButtonClick');
        if (exercise?.words && exercise.words.length > 0) {
            const requests = exercise.words.map(async (item) => {
                // console.log({ item })
                await makeRequestAi('AutoGapFillExercise', (newState) => updateStatusState(item.word, newState), { word: item.word, meaning: item.definitions.toString() }, (newState) => updateResultState(item.word, newState));
            })
            await Promise.all(requests);
            // console.log({ ResultState })
        }
    };

    const updateByWord = async (word) => {
        await makeRequestAi('AutoGapFillExercise', (newState) => updateStatusState(word, newState), { word: word, meaning: findDefinitions(word[0], exercise).toString() }, (newState) => updateResultState(word, newState));
    };


    return (
        <div>
            <ExerciseTitleComponent>Генерировать предложения</ExerciseTitleComponent>
            <div>
                <GenerateButtonComponent onClick={handleStartButtonClick} disabled={exercise?.words && exercise.words.length === 0}>Создать предложения</GenerateButtonComponent>
            </div>

            {exercise?.words && exercise.words.length > 0 && exercise.words.map((item) => (
                <GeneratedFillTheGapSentensesComponent exercise={exercise} exerciseType={exerciseType} key={"generateSentences" + item.word} result={ResultState[item.word]} status={StatusState[item.word]} updateByWord={updateByWord} word={item.word} definitions={item.definitions} />
            ))}
        </div>
    )
}

export default GenerateSentencesFillTheGapComponent