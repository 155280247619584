import React, { useState } from 'react'
import { useEnglishLessonStore } from '../../store/englishLessonStore.js';
import { makeRequestAi } from '../../services/makeRequestAi.js';
import TextAddToWordListWithButtonComponent from '../TextAddToWordListWithButtonComponent.js';
import GenerateButtonComponent from '../elements/buttons/GenerateButtonComponent.js';
import LoadingComponent from '../elements/LoadingComponent.js';
import { ArrowRepeat } from 'react-bootstrap-icons';
import DeleteButtonComponent from '../elements/buttons/DeleteButtonComponent.js';

function AutoChooseComponent() {
    const [status, setStatus] = useState(false);
    const [result, setResult] = useState(false);
    const { inputText, wordList } = useEnglishLessonStore()

    const vars = {
        text: inputText,
        usedwords: wordList && wordList.length > 0 ? `This words are already used: "${wordList.join(',')}", so find another.` : ''
    }

    return (
        <div>
            <GenerateButtonComponent onClick={() => makeRequestAi('AutoChoose', setStatus, vars, setResult)} disabled={!inputText}><ArrowRepeat /></GenerateButtonComponent>
            {!!status && <><LoadingComponent /></>}
            {!!result && <><DeleteButtonComponent style={{ marginLeft: '10px' }} onClick={() => setResult(false)} /></>}
            <div>
                {result && <> <TextAddToWordListWithButtonComponent inputText={result} /></>}
            </div>
        </div>
    )
}

export default AutoChooseComponent