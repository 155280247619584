import React from 'react'
import { BrightnessHighFill, MoonFill } from 'react-bootstrap-icons'
import { useEnglishLessonStore } from '../../store/englishLessonStore'

function ThemeButtonComponent() {
    const { theme, setTheme } = useEnglishLessonStore()

    const changeTheme = () => {
        setTheme(theme === "light" ? "dark" : "light")
    }
    return (
        <div className="theme-button">
           {theme !== "light" ? <BrightnessHighFill onClick={changeTheme} /> : <MoonFill onClick={changeTheme} />} 
        </div>
    )
}

export default ThemeButtonComponent