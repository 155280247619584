import React from 'react'
import { pb } from '../../services/pocketbase'
import { Navigate, Outlet } from 'react-router-dom'
import { ROUTES } from './routes';
import { useAuth } from '../../store/AuthStore';

const ProtectedRouteComponent = () => {
    const { user } = useAuth();
    return pb.authStore.isValid && user ? <Outlet /> : <Navigate to={ROUTES.SIGNIN} replace />;
};

export default ProtectedRouteComponent