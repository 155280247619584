import React from 'react'
import { useEnglishLessonStore } from '../../../store/englishLessonStore'
import MinusButtonComponent from '../buttons/MinusButtonComponent'
import ExerciseTitleComponent from './ExerciseTitleComponent'
import { useExersisesStore } from '../../../store/exercisesStore'

function ChooseWordsComponent({ exercise, exerciseType }) {
    const { wordsDictionary } = useEnglishLessonStore()
    const { editTypeItemExercise } = useExersisesStore()

    const addWordsToExercise = (word) => {
        editTypeItemExercise(exerciseType, exercise.id, { ...exercise, words: [...exercise.words, { word: word, definitions: wordsDictionary[word].definitions }] })
    }

    const deleteWordsFromExercise = (word) => {
        editTypeItemExercise(exerciseType, exercise.id, { ...exercise, words: exercise.words.filter(item => item.word !== word) })
    }

    return (
        <>
            <div>
                <ExerciseTitleComponent>Выберите слова</ExerciseTitleComponent>
                <div className='d-flex flex-wrap'>
                    {
                        Object.keys(wordsDictionary).filter(key => ![...(exercise.words ? exercise.words.map(w => w.word) : []), ''].flat().includes(key)).map((key) => (
                            <span className="words-list-item-component pointer" key={"wordsDictionary" + key} onClick={() => addWordsToExercise([key])}>{key}</span>
                        ))
                    }
                </div>
            </div>
            {exercise?.words && exercise?.words && <ExerciseTitleComponent>Выбранные</ExerciseTitleComponent>}
            <div className='d-flex flex-wrap'>
                {exercise?.words && exercise.words.map((item) => (
                    <span className="words-list-item-component " key={"exerciseWords" + item.word}>{item.word} <MinusButtonComponent title="удалить" onClick={() => { deleteWordsFromExercise(item.word) }} /></span>
                ))}
            </div >
        </>
    )
}

export default ChooseWordsComponent