import React from 'react'
import DictionaryItem from './DictionaryItemComponent'

function AutoMeaningApiDictionariesComponent({ word, error, dictionaryInfo }) {
  return (
    <>
      {dictionaryInfo && Array.isArray(dictionaryInfo) && dictionaryInfo.length > 0 && dictionaryInfo.map((info, index) => <DictionaryItem word={word} dictionaryInfo={info} error={error} />)}
    </>

  )
}

export default AutoMeaningApiDictionariesComponent