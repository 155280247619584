import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useEnglishLessonStore } from '../store/englishLessonStore'

function WordsAddFormComponent() {
    const { addToWordList } = useEnglishLessonStore()
    const [inputText, setInputText] = useState()

    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     addWordToWordList(inputText.trim().toLowerCase())
    //     setInputText('')
    // }

    const handleEnterKeyPress = (event) => {
        if (event.key === 'Enter') {
            addToWordList(inputText.trim().toLowerCase())
            setInputText('')
        }
    }

    return (
        <>
            <Form.Control
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={handleEnterKeyPress}
                type="text"
                placeholder="Добавить слово"
                value={inputText}
                className='shadow-box mb-2'
            />
        </>
    )
}

export default WordsAddFormComponent