import toast from 'react-hot-toast';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export const useExersisesStore = create(
    persist(
        (set, get) => ({
            // Exercises
            exercises: {},

            addTypeExercise: (exerciseType) => {
                set((state) => {
                    const newExercises = { ...state.exercises };
                    if (!newExercises[exerciseType]) {
                        newExercises[exerciseType] = [];
                    }
                    return { exercises: newExercises };
                });
            },

            addTypeItemExercise: (exerciseType, newExercise) => {
                set((state) => {
                    const newExercises = { ...state.exercises };
                    if (!newExercises[exerciseType]) {
                        newExercises[exerciseType] = [];
                    }
                    const exerciseId = crypto.randomUUID();
                    newExercises[exerciseType].push({ id: exerciseId, ...newExercise });
                    return { exercises: newExercises };
                });
            },

            editTypeItemExercise: (exerciseType, exerciseId, updatedExercise) => {
                set((state) => {
                    const newExercises = { ...state.exercises };
                    const exerciseList = newExercises[exerciseType];

                    if (!exerciseList) {
                        toast.error('Exercise type does not exist.');
                        return state;
                    }

                    const exerciseIndex = exerciseList.findIndex(ex => ex.id === exerciseId);
                    if (exerciseIndex === -1) {
                        toast.error('Exercise not found.');
                        return state;
                    }

                    exerciseList[exerciseIndex] = { id: exerciseId, ...updatedExercise };
                    return { exercises: newExercises };
                });
            },

            deleteTypeItemExercise: (exerciseType, exerciseId) => {
                set((state) => {
                    const newExercises = { ...state.exercises };
                    const exerciseList = newExercises[exerciseType];

                    if (!exerciseList) {
                        toast.error('Exercise type does not exist.');
                        return state;
                    }

                    const exerciseIndex = exerciseList.findIndex(ex => ex.id === exerciseId);
                    if (exerciseIndex === -1) {
                        toast.error('Exercise not found.');
                        return state;
                    }

                    exerciseList.splice(exerciseIndex, 1);
                    return { exercises: newExercises };
                });
            },

            deleteTypeExercise: (exerciseType) => {
                set((state) => {
                    const newExercises = { ...state.exercises };
                    if (!newExercises[exerciseType]) {
                        toast.error('Exercise type does not exist.');
                        return state;
                    }
                    delete newExercises[exerciseType];
                    return { exercises: newExercises };
                });
            },

            clearAllExercise: () => set(() => ({ exercises: {} })),
        }),
        {
            name: 'english-exercises-storage',
            storage: createJSONStorage(() => localStorage),
        }
    )
);