import React from 'react'
import { useEnglishLessonStore } from '../../store/englishLessonStore'
import DeleteButtonComponent from './buttons/DeleteButtonComponent'

function ClearWords() {
    const { clearWordList, wordList, clearWordsDictionary } = useEnglishLessonStore()

    const handleClick = () => {
        // make dialog here to confirm clearing list - yes or now
        const confirmed = window.confirm('Удалить все слова?');
        if (confirmed) {
            clearWordList();
            clearWordsDictionary();
        }
    }
    return (
        <div><div>{wordList.length > 0 ? <DeleteButtonComponent onClick={handleClick}  title="Удалить все слова из списка" /> : ''}</div></div>
    )
}

export default ClearWords