import React from 'react'
import { useEnglishLessonStore } from '../store/englishLessonStore'
import WordsListItemComponent from './WordsListItemComponent';

function WordsListComponent() {
    const { wordList } = useEnglishLessonStore();

    return (
        <>
            <div>
                {wordList.map((word) => <WordsListItemComponent word={word} key={word} />)}
            </div>
        </>
    )
}

export default WordsListComponent