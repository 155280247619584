// vars for prompts
// {text} {number} {word}
// local
// 4072cb1e-9b28-4de8-a2d2-96de2606fa3c basic
// 9ce521ec-0a6f-4c92-9cee-ca759b5c7677 long
// 9afc7bf5-937c-422e-ab70-0ea10d2e12cf json
// 6d13335b-6af2-4ae5-b7c6-e2b0748d5ebc json short
// if dev
let is_no_dev = !process.env.REACT_APP_API_URL.includes('api-dev')

let basic = is_no_dev ? "020e053a-2f74-4960-acb9-ea313251005e" : "4072cb1e-9b28-4de8-a2d2-96de2606fa3c"
let long = is_no_dev ? "486de723-f64f-47ba-837b-8d72269c126c" : "9ce521ec-0a6f-4c92-9cee-ca759b5c7677"
let json = is_no_dev ? "732279ee-be8a-469f-a237-74eb4aa341a8" : "9afc7bf5-937c-422e-ab70-0ea10d2e12cf"
let json_short = is_no_dev ? "21b00f27-47a1-4327-b467-1e66e1847f2b" : "6d13335b-6af2-4ae5-b7c6-e2b0748d5ebc"


export const ai = {
    AutoChoose: {
        inputValue: `{text}\n Find some (less than 20 items) interesting to learn words and short collocations to learn for students in this text. Don't numerate them. Only return them like this '- word'. No other text. Don't use * symbol. Make sure that they are from the text. /?answer-only No intro, please. {usedwords}`,
        flowIdOrName: basic,
    },
    AutoMeaning: {
        inputValue: `<text>
        {text}
        </text>
        <Word-collocation>
        {word}
        </Word-collocation>
        <Example>
        {
        word: "break free",
        russianTranslation: "освободиться",
        definition: "to stop being affected by something that limits what you can do",
        usages: 
            [
                "All the bad habits your going to break free from.",
                "Break free from that mindset?",
                "Is it possible to break free from that mindset?",
                "I know it's hard to break free from any abusive relationship.",
                "His mentor helped him to break free from the world of crime.",
                "To break free from this depression.",
                "Break free from these racial stereotypes." 
            ]
        }
        </Example>
        <Task>
        Find '{word}' in this text. Write meaning of this one word or collocation that used in this text. Show me word:'{word}', перевод слова или фразы на русский язык (переведи литературно с учетом значения), definition and seven usages of it in sentenses with this word '{word}' same meaning as in text. You can use Example. Return only word, definition and sentenses. No other text. Dont use * symbol or Markdown.
        <shema>
        {
            "$schema": "http://json-schema.org/draft-04/schema#",
            "type": "object",
            "properties": {
                "word": {
                "type": "string"
                },
                "russianTranslation": {
                "type": "string"
                },
                "definition": {
                "type": "string"
                },
                "usages": {
                "type": "array",
                "items": [
                    {
                    "type": "string"
                    },
                    {
                    "type": "string"
                    },
                    {
                    "type": "string"
                    },
                    {
                    "type": "string"
                    },
                    {
                    "type": "string"
                    },
                    {
                    "type": "string"
                    },
                    {
                    "type": "string"
                    }
                ]
                }
            },
            "required": [
                "word",
                "russianTranslation",
                "definition",
                "usages"
            ]
        }
        </shema>
        </Task>`,
        flowIdOrName: json,
    },
    AutoGapFillExercise: {
        inputValue: `
        <Task>
            Generate short A2 level five sentences with usages of selected word or phrase using its meaning.
            ONLY include resulted sentences in your response. Dont answer me anything else. Put selected words and phrases in square brackets inside sentences like in example below.Return only sentenses. No other text. Dont use * symbol or Markdown. Use json schema.
        </Task>
        <Example>
            selected word: 
            1. welling - rise up to the surface and spill or be about to spill
            Result sentences:
            {
                sentences: [
                    "The tears were [wellling] in her eyes.",
                    "Her emotions were [welling] inside.", 
                    "The excitement was [welling] in him as he waited for the concert to start.",
                    "The rain had been [welling] up all morning.",
                    "Her anger was [wellling] up, and she couldn't hold it in any longer.",
                    "The laughter was [wellling] out of her mouth."
                ]
            }
            
        </Example>
        <Selected Words and Phrases>
            {word} - {meaning}
        </Selected Words and Phrases>
        <shema>
        {
            "$schema": "http://json-schema.org/draft-04/schema#",
            "type": "object",
            "properties": {
                "sentences": {
                "type": "array",
                "items": [
                    {
                    "type": "string"
                    },
                    {
                    "type": "string"
                    },
                    {
                    "type": "string"
                    },
                    {
                    "type": "string"
                    },
                    {
                    "type": "string"
                    },
                    {
                    "type": "string"
                    },
                    {
                    "type": "string"
                    }
                ]
                }
            },
            "required": [
                "sentences"
            ]
        }
        </shema>
        <Sentences>`,
        flowIdOrName: json,
    },
    CorrectTranscription: {
        inputValue: `
            {text}

            This is a transcript of video. Please make text from this with punctuation and logically separate sentences to paragraphs. Do not change any text, only punctuation and uppercase letters. /?answer-only . No intro, please.
            `,
        flowIdOrName: long,
    },
}
