import React from 'react'
import { useEnglishLessonStore } from '../../store/englishLessonStore'
import { copyToClipboard } from '../../functions/copyToClipboard';
import CopyToClipboardButtonComponent from './buttons/CopyToClipboardButtonComponent';

function WordsListHeaderComponent() {
    const { wordList } = useEnglishLessonStore()

    const onClickHandler = () => {
        if (wordList.length > 0) {
            // Convert the array to a comma-separated string
            const wordString = wordList.join(', ');
            // Copy the string to the clipboard
            copyToClipboard(wordString)
        } else {
            alert('The word list is empty.');
        }
    }

    return (
        <div style={{ display: 'flex', verticalAlign: 'middle', alignItems: 'center' }}>
            <h1 style={{ float: 'left' }}>Слова</h1>
            {wordList.length > 0 && <CopyToClipboardButtonComponent onClickHandler={onClickHandler} />}
        </div>
    )
}

export default WordsListHeaderComponent
