import React from 'react';
import { useEnglishLessonStore } from '../../store/englishLessonStore';
import AddToButtonComponent from './buttons/AddToButtonComponent';

const DictionaryItemComponent = ({ word, dictionaryInfo, error }) => {
    const { wordsDictionary, addWordToDictionary } = useEnglishLessonStore()


    const info = dictionaryInfo || {};
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }} className='fw-bold'>
                <h4> 📖 </h4>
            </div>

            {error && <div>{error}</div>}
            {!error && info && info?.word && <div className="bg-light-light block-with-definition">
                {info?.phonetics && info?.phonetics.length === 0 && <p>{info?.phonetic} {wordsDictionary[word]?.phonetics.includes(info?.phonetic) ? '' : <AddToButtonComponent onClick={() => { addWordToDictionary(word, [], [], [], [info?.phonetic]) }} />}</p>}
                {info?.phonetics.map((item, idx) => (
                    <div key={idx}>
                        <span>{item.text !== '' ? item.text : info.phonetic} {item.text && wordsDictionary[word]?.phonetics.includes(item.text) ? '' : <AddToButtonComponent onClick={() => { addWordToDictionary(word, [], [], [], [item.text ? item.text : info?.phonetic]) }} />} </span>
                        {item.audio && <><br /><audio controls className="example"><source src={item.audio} type="audio/mpeg" /></audio></>}

                    </div>
                ))}
                {info?.origin && <p><strong>Origin:{info?.origin}</strong></p>}
                {info.meanings && info.meanings.length > 0 && <div>🔹<b>{word}</b>
                    <h5 className='pt-4'>Meanings</h5>
                    {info.meanings.map((meaning, index) => (
                        <div key={index} className="meaning">
                            <div className="pt-4 text-body-secondary">{meaning.partOfSpeech}</div>
                            {meaning.definitions.map((def, idx) => (
                                <div key={idx}>
                                    <div className='py-2'>{def.definition} {wordsDictionary[word]?.definitions.includes(def.definition) ? '' : <AddToButtonComponent onClick={() => { addWordToDictionary(word, [def.definition]) }} />}</div>
                                    {def.example && <ul><li>{def.example} {wordsDictionary[word]?.usages.includes(def.example) ? '' : <AddToButtonComponent onClick={() => { addWordToDictionary(word, [], [def.example]) }} />}</li></ul>}
                                </div>
                            ))}

                        </div>
                    ))}
                </div>}

            </div>
            }
        </>

    );
};

export default DictionaryItemComponent;