import React, { useRef } from 'react'
import DeleteButtonComponent from '../buttons/DeleteButtonComponent'
import { Col, Row } from 'react-bootstrap'
import { useExersisesStore } from '../../../store/exercisesStore'
import CopyToClipboardButtonComponent from '../buttons/CopyToClipboardButtonComponent'
import toast from 'react-hot-toast'
import FindRightDefinitionChooseWordsComponent from './FindRightDefinitionChooseWordsComponent'

function FindRightDefinitionExerciseBlockComponent({ exercise, exerciseType }) {
  const { deleteTypeItemExercise } = useExersisesStore()
  const tableRef = useRef(null); // Create a ref for the table element



  const deleteHandler = () => {
    if (window.confirm('Удалить задание?')) {
      deleteTypeItemExercise(exerciseType, exercise.id)
    }
  }

  const wordsString = exercise?.pairs && exercise.pairs.map((item) => (
    item.word
  )).join(', ')
  console.log({ sent: exercise })
  const pairsArray = exercise?.pairs && exercise.pairs


  const onClickCopyHandler = async () => {
    if (tableRef.current) {
      const html = tableRef.current.outerHTML; // Get the HTML of the table

      try {
        // Use the Clipboard API to copy HTML to the clipboard
        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([html], { type: 'text/html' }),
            'text/plain': new Blob([tableRef.current.innerText], { type: 'text/plain' }) // Fallback to plain text
          })
        ]);
        toast.success('Скопировано');
      } catch (err) {
        console.error('Failed to copy: ', err);
        toast.error('Ошибка при копировании');
      }
    }
  }


  return (
    <>
      <div className='block-with-definition bg-blue shadow-box'>
        <div className='d-flex justify-content-between'>
          <div>{wordsString}</div>
          <span className='px-2'><DeleteButtonComponent onClick={deleteHandler} /></span>
        </div>
        <div>
          <h4>Упражнение <CopyToClipboardButtonComponent onClickHandler={() => onClickCopyHandler()} /></h4>
          <table ref={tableRef} className='table table-bordered'>
            <tbody>
              {pairsArray && pairsArray.map((items) => (
                <tr key={items.word}>
                  <td >{items.word}</td>
                  <td >{items.definition}</td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>

      </div>
      <div className='block-with-definition shadow-box'>
        <Row>
          <Col>
            <FindRightDefinitionChooseWordsComponent exercise={exercise} exerciseType={exerciseType} />
          </Col>
        </Row>
        <Row>
          <Col>
            {/* <GenerateSentencesFillTheGapComponent exerciseType={exerciseType} exercise={exercise} /> */}
          </Col>
        </Row>

      </div>

    </>

  )
}

export default FindRightDefinitionExerciseBlockComponent