import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import useAuthStore, { useAuth } from '../../store/AuthStore';
import { pb } from '../../services/pocketbase';
import google from '../../assets/google.svg'
import yandex from '../../assets/yandex.svg'
import { humanDate } from '../../functions/dateHuman';
import { balanceShow } from '../../functions/balanceShow';

function SignInPage() {
    const { user, login } = useAuthStore()
    const { logout } = useAuth()

    const signInWithGoogle = () => {
        login('google')
        // console.log({ user })
        // console.log({ authData })
    }
    const signInWithYandex = () => {
        login('yandex')
    
    }

        // console.log({ user })
        // console.log({ authData })

    return (
        <Container>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col md={8} className='bg-solid signin-page-block'>
                    {!pb.authStore.isValid && (
                        <Row className='d-flex justify-content-center '>
                            <Col sm={6} className='d-flex justify-content-center'>
                                <Button className='login-btn' onClick={signInWithGoogle}><img alt='google' src={google} width={'24px'} /> Войти с помощью Google</Button>
                            </Col>
                            <Col sm={6} className='d-flex justify-content-center'>
                                <Button className='login-btn' onClick={signInWithYandex}><img alt='google' src={yandex} width={'24px'} /> Войти с помощью Яндекс</Button>
                            </Col>
                        </Row>
                    )}
                    {pb.authStore.isValid && user && (
                        <Row className='reversed'>
                            <Col sm={8}>
                                {
                                    user?.avatar && user?.avatar !== '' &&
                                    (
                                        <div className="circle-image-big">
                                            <img src={pb.files.getUrl(user, user.avatar)} alt={user.name} className='avatar-big' />
                                        </div>
                                    )
                                }
                                <div className='info py-3'>
                                    <div>
                                        <h2>{user.name ? user.name : user.username}</h2>
                                    </div>
                                    <div>
                                        {user.email}
                                    </div>
                                    <div>
                                        {user.username}
                                    </div>
                                    <div className='text-muted small'>
                                        Дата регистрации:  {humanDate(user.created)}
                                    </div>
                                </div>
                                <Button onClick={logout}>Выйти</Button>
                            </Col>
                            <Col sm={4} className='text-end'>
                                <div>Баланс: {balanceShow(0)}</div>
                            </Col>

                        </Row>
                    )
                    }
                </Col>

            </Row>
        </Container>

    )
}

export default SignInPage